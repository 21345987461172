<template>
    <v-container>
      <v-row>
        <v-col>
          <v-card class="py-5" elevation="8">
            <h1
              class="text-center primary--text"
              style="font-family: Kelly Slab !important"
            >
              Fatura do CPF/CNPJ {{ $store.state.cpf }}
            </h1>
            <h4 class="text-center">
              Data:
              {{
                new Date()
                  .getDate()
                  .toString()
                  .padStart(2, "0")
              }}/ {{ (new Date().getMonth() + 1).toString().padStart(2, "0") }}/
              {{ new Date().getFullYear() }}
            </h4>
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="text-center justify-center align-center">
          <v-card class="d-flex justify-center" flat tile>
            <v-card class="pa-2" tile width="700" elevation="0">
              <v-container>
                <v-row no-gutters>
                  <v-col class="d-flex flex-column justify-center">
                    <v-row class="d-flex flex-column justify-center">
                      <p class="text-h5 my-0" v-if="avista">A Vista</p>
                      <p class="text-h5 my-0" v-else>Parcelado</p>
  
                      <p class="my-0 subtitle-1" v-if="avista">
                        Valor R$ {{ item.valor | dinheiro }}
                      </p>
                      <p class="my-0 subtitle-1" v-else>
                        Entrada de R$ {{ item.valor | dinheiro }}
                      </p>
  
                      <p class="my-0 subtitle-1" v-if="!avista">
                        Mais {{ item.parcelas }} X de R$
                        {{ item.valorP | dinheiro }}
                      </p>
                    </v-row>
                    <v-row class="d-flex flex-column justify-center px-8">
                      <v-text-field
                        label="Email para receber o boleto"
                        v-model="email"
                        prepend-icon="mdi-mail"
                        type="text"
                      ></v-text-field>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-date-picker
                      :min="picker3"
                      :max="picker2"
                      v-model="picker"
                      :first-day-of-week="0"
                      locale="pt"
                    ></v-date-picker
                  ></v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-btn block color="primary" @click="negociarFatura()">
                  Negociar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  //import { api } from "@/conf/api";
  
  export default {
    name: "PlanoViasat2",
    data() {
      return {
        faturas: null,
        item: null,
        consolidado: [],
        parcelado: [],
        picker: new Date().toISOString().substr(0, 10),
        picker2: new Date().toISOString().substr(0, 10),
        picker3: new Date().toISOString().substr(0, 10),
        email: "",
        avista: false,
      };
    },
    beforeMount() {
      this.pegaPlanos();
    },
    filters: {
      dinheiro: function(value) {
        return parseFloat(value)
          .toFixed(2)
          .replace(".", ",");
      },
      dataF: function(d2) {
        let d = d2.substr(0, 10);
        return d.substr(8, 2) + "/" + d.substr(5, 2) + "/" + d.substr(0, 4);
      },
    },
    methods: {
      async pegaPlanos() {
        try {
          let faturas = this.$store.state.negoc_atual;
          this.item = faturas;
          if (this.item.parcelas == "0") {
            this.avista = true;
          }
          let d = new Date();
          d.setDate(d.getDate() + 7);
          this.picker2 = d.toISOString().substr(0, 10);
        } catch (e) {
          console.log(e);
        }
      },
      async negociarFatura() {
        //let cpf = this.$store.state.cpf;
        // let contrato = this.$store.state.divida_atual.contrato;
        try {
          await this.$store.dispatch("marcaPacoteAtual", "N-VIASAT");
          // await this.$store.dispatch("marcaNegocAtual", item);
          await this.$store.dispatch("marcaVencAtual", this.picker);
  
          await this.$store.dispatch("marcaEmail", this.email);
  
          this.$router.push("NegocViasat");
        } catch (e) {
          console.log(e);
        }
      },
    },
  };
  </script>
  
  <style></style>
  